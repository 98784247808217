// import ChartDataLabels from "chartjs-plugin-datalabels";
export const ADDED_TO_CHART_SMALL_SCORING = 0.1
export const ADDED_TO_CHART_LARGE_SCORING = 200

export const COLORS = [
    '#FE8176',
    '#FDE281',
    '#9BD770',
    '#8C78E8',
    '#FEAB6D',
    '#F1F791',
    '#678FFE',
    '#F033B4',
    '#F37C84',
    '#FE8F77',
    '#79BEA8',
    '#C0DF81',
    '#FED777',
    '#FEEC86',
    '#A33AF2',
    '#8A8AEF',
]

export const topBottomAttributesOptions = {
	options: {
		tooltips: {
			callbacks: {
				title: (context, chart) => {
					const currentDatasetIndex = context.findIndex(item => item.xLabel && item.x)
					return chart.labels[context[currentDatasetIndex].index]
				},
				label: (context, chart) => {
					return Number(context.value) ? `Value: ${context.value}` : "";
				}
			},
		},
		plugins: {
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: function (value, context) {
					return Math.round(value * 100) / 100;
				},
			},
		},
	},
	data: null,
	maxScore: 0
}

export const cdcTrendOptions = {
	options: {
		tooltips: {
			callbacks: {
				label: (context) => {
					return Number(context.value) ? `Value: ${context.value}` : "";
				},
			},
		},
		plugins: {
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: function (value, context) {
					return Math.round(value * 100) / 100;
				},
			},
		},
	},
	data: null,
	maxScore: 0
}